
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "eeff5a0b-1c34-4fb0-95e5-d6b10856c1d9"
  },
  "ui": {
    "primary": "green",
    "gray": "cool",
    "colors": [
      "red",
      "orange",
      "amber",
      "yellow",
      "lime",
      "green",
      "emerald",
      "teal",
      "cyan",
      "sky",
      "blue",
      "indigo",
      "violet",
      "purple",
      "fuchsia",
      "pink",
      "rose",
      "persian-blue",
      "secondary",
      "primary"
    ],
    "strategy": "merge"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/vercel/path0/app.config.ts"
import cfg1 from "/vercel/path0/node_modules/@nuxt/ui-pro/app.config.ts"
import cfg2 from "/vercel/path0/layers/core/app.config.ts"
import cfg3 from "/vercel/path0/layers/auth/app.config.ts"
import cfg4 from "/vercel/path0/layers/workflows/app.config.ts"
import cfg5 from "/vercel/path0/layers/signt/app.config.ts"
import cfg6 from "/vercel/path0/layers/people/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, inlineConfig)
