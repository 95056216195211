import { useState } from '#app'

export interface Entity {
  _id: string;
  name: string;
  consumer_id: string;
  apikey?: string;
}

export interface UserAuth {
  _id: string;
  entity_id: string;
  email: string;
  name: string;
  ory_id: string;
  role: string;
  status: string;
}
export interface ResultUserAuth {
  entity: Entity;
  user: UserAuth;
}

export interface ResponseUserAuth {
  result: ResultUserAuth;
  success: boolean;
}

const useUserAuth = () => {
  const userAuthData = useState<ResultUserAuth>('userAuth', () => { })
  const setUserAuthData = (user: ResultUserAuth) => {
    userAuthData.value = user
  }

  return {
    userAuthData,
    setUserAuthData
  }
}
export default useUserAuth
