// import { links } from '~auth/utils/routes'
import { RolesEnum } from '~auth/enums/roles.enum'
import { useRemApi } from '~core/composables/useRem'

const fetchUserAuthData = async () => {
  const config = useRuntimeConfig()
  const urlAbsolute = config.public?.apiUrl?.replace('/api', '')
  const { data, error } = await useRemApi<any>(`${urlAbsolute}/users/me`)
  if (error.value) {
    return null
  }
  return data.value
}

const ROUTES_AUTH = ['recovery', 'verification', 'failed', 'login']

const routesAllowed = (path: string) => ROUTES_AUTH.some(p => path.includes(p))


export default defineNuxtRouteMiddleware(async (to: any, from: any) => {
  const config = useRuntimeConfig()
  const { isLinkAllowedByRole } = useSidebarLinks()
  try {
    // If the environment is development and the configuration is not development with Ory, return
    if (config.public.developmentWithOry !== 'true' && process.env.NODE_ENV === 'development') return
    if (process.server && config.public.developmentWithOry === 'true') return
    
    // Get session ory
    const sessionData = (await useOryValidateSession())?.session

    // If there is no session and the route is different from the permit of Auth, redirect to login
    if (!sessionData.value && !routesAllowed(to.path)) {
      return navigateTo('/auth/login')
    }

    // If there is no session and the pertence route to those of Auth, allow navigation
    if ((!sessionData.value && routesAllowed(to.path)) && from.path !== to.path) {
      return
    }

    // Set user auth ory data
    const { setUserAuthOryData } = useUserAuthOry()
    setUserAuthOryData(sessionData.value)

    // If there is session, but was initiated by a recovery method, redirect configuration, to change the password
    if ((sessionData.value && isLinkRecoveryMethod(sessionData.value)) && from.path !== to.path) {
      return navigateTo('/configuration')
    }

    // If this is working at home with ORY, allow navigation
    if(config.public.developmentWithOry === 'true') return

    // Get user (role) data from the API
    const userData = await fetchUserAuthData()

    // Set user auth data
    const { setUserAuthData } = useUserAuth()
    setUserAuthData(userData)

    // If the user has admin role, allow navigation
    if (userData?.user?.role === RolesEnum.ADMIN && from.path !== to.path) {
      return // Allow navigation
    }
  
    // If the user has no admin role, validate whether the route is allowed by the role
    if (isLinkAllowedByRole(to.path, userData?.user?.role) && from.path !== to.path) return // Allow navigation

    if(to.path === from.path) return
  
    // If the user does not meet the required role, abort navigation
    return abortNavigation()

  } catch (error: any) {
    console.log('error', error)
    useBugsnag().notify(error)
    if (error?.response?.status === 401 && (routesAllowed(to.path) && from.path !== to.path)) {
      return
    }
    return navigateTo('/auth/login')
  }
})