import { default as loginILQdBdZzRsMeta } from "/vercel/path0/pages/auth/login.vue?macro=true";
import { default as recoveryKWIC6ER9SyMeta } from "/vercel/path0/pages/auth/recovery.vue?macro=true";
import { default as verificationcd6PBCgVZgMeta } from "/vercel/path0/pages/auth/verification.vue?macro=true";
import { default as indexJGn4BlGNFaMeta } from "/vercel/path0/pages/configuration/index.vue?macro=true";
import { default as userscz9xC7FTwXMeta } from "/vercel/path0/pages/configuration/users.vue?macro=true";
import { default as configurationzKoiJ3MxCcMeta } from "/vercel/path0/pages/configuration.vue?macro=true";
import { default as failedNIdIlZANtlMeta } from "/vercel/path0/pages/failed.vue?macro=true";
import { default as homeaAKJ71q3qIMeta } from "/vercel/path0/pages/home.vue?macro=true";
import { default as inboxH6SWuI0DquMeta } from "/vercel/path0/pages/inbox.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as membersoHlYPzbFpmMeta } from "/vercel/path0/pages/settings/members.vue?macro=true";
import { default as notificationsvmt0hH6diVMeta } from "/vercel/path0/pages/settings/notifications.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as usersrOJT0s7kzFMeta } from "/vercel/path0/pages/users.vue?macro=true";
import { default as index2sIr7sMu1kMeta } from "/vercel/path0/pages/workflows/index.vue?macro=true";
import { default as workflowswfUdDI78SIMeta } from "/vercel/path0/pages/workflows.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubUHgG5aOkzG } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: loginILQdBdZzRsMeta?.name ?? "auth-login",
    path: loginILQdBdZzRsMeta?.path ?? "/auth/login",
    meta: loginILQdBdZzRsMeta || {},
    alias: loginILQdBdZzRsMeta?.alias || [],
    redirect: loginILQdBdZzRsMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: recoveryKWIC6ER9SyMeta?.name ?? "auth-recovery",
    path: recoveryKWIC6ER9SyMeta?.path ?? "/auth/recovery",
    meta: recoveryKWIC6ER9SyMeta || {},
    alias: recoveryKWIC6ER9SyMeta?.alias || [],
    redirect: recoveryKWIC6ER9SyMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/recovery.vue").then(m => m.default || m)
  },
  {
    name: verificationcd6PBCgVZgMeta?.name ?? "auth-verification",
    path: verificationcd6PBCgVZgMeta?.path ?? "/auth/verification",
    meta: verificationcd6PBCgVZgMeta || {},
    alias: verificationcd6PBCgVZgMeta?.alias || [],
    redirect: verificationcd6PBCgVZgMeta?.redirect,
    component: () => import("/vercel/path0/pages/auth/verification.vue").then(m => m.default || m)
  },
  {
    name: configurationzKoiJ3MxCcMeta?.name ?? undefined,
    path: configurationzKoiJ3MxCcMeta?.path ?? "/configuration",
    meta: configurationzKoiJ3MxCcMeta || {},
    alias: configurationzKoiJ3MxCcMeta?.alias || [],
    redirect: configurationzKoiJ3MxCcMeta?.redirect,
    component: () => import("/vercel/path0/pages/configuration.vue").then(m => m.default || m),
    children: [
  {
    name: indexJGn4BlGNFaMeta?.name ?? "configuration",
    path: indexJGn4BlGNFaMeta?.path ?? "",
    meta: indexJGn4BlGNFaMeta || {},
    alias: indexJGn4BlGNFaMeta?.alias || [],
    redirect: indexJGn4BlGNFaMeta?.redirect,
    component: () => import("/vercel/path0/pages/configuration/index.vue").then(m => m.default || m)
  },
  {
    name: userscz9xC7FTwXMeta?.name ?? "configuration-users",
    path: userscz9xC7FTwXMeta?.path ?? "users",
    meta: userscz9xC7FTwXMeta || {},
    alias: userscz9xC7FTwXMeta?.alias || [],
    redirect: userscz9xC7FTwXMeta?.redirect,
    component: () => import("/vercel/path0/pages/configuration/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: failedNIdIlZANtlMeta?.name ?? "failed",
    path: failedNIdIlZANtlMeta?.path ?? "/failed",
    meta: failedNIdIlZANtlMeta || {},
    alias: failedNIdIlZANtlMeta?.alias || [],
    redirect: failedNIdIlZANtlMeta?.redirect,
    component: () => import("/vercel/path0/pages/failed.vue").then(m => m.default || m)
  },
  {
    name: homeaAKJ71q3qIMeta?.name ?? "home",
    path: homeaAKJ71q3qIMeta?.path ?? "/home",
    meta: homeaAKJ71q3qIMeta || {},
    alias: homeaAKJ71q3qIMeta?.alias || [],
    redirect: homeaAKJ71q3qIMeta?.redirect,
    component: () => import("/vercel/path0/pages/home.vue").then(m => m.default || m)
  },
  {
    name: inboxH6SWuI0DquMeta?.name ?? "inbox",
    path: inboxH6SWuI0DquMeta?.path ?? "/inbox",
    meta: inboxH6SWuI0DquMeta || {},
    alias: inboxH6SWuI0DquMeta?.alias || [],
    redirect: inboxH6SWuI0DquMeta?.redirect,
    component: () => import("/vercel/path0/pages/inbox.vue").then(m => m.default || m)
  },
  {
    name: settingsyZZu4Rd1aAMeta?.name ?? undefined,
    path: settingsyZZu4Rd1aAMeta?.path ?? "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    alias: settingsyZZu4Rd1aAMeta?.alias || [],
    redirect: settingsyZZu4Rd1aAMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: indexKIRFIU1ID4Meta?.name ?? "settings",
    path: indexKIRFIU1ID4Meta?.path ?? "",
    meta: indexKIRFIU1ID4Meta || {},
    alias: indexKIRFIU1ID4Meta?.alias || [],
    redirect: indexKIRFIU1ID4Meta?.redirect,
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: membersoHlYPzbFpmMeta?.name ?? "settings-members",
    path: membersoHlYPzbFpmMeta?.path ?? "members",
    meta: membersoHlYPzbFpmMeta || {},
    alias: membersoHlYPzbFpmMeta?.alias || [],
    redirect: membersoHlYPzbFpmMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/members.vue").then(m => m.default || m)
  },
  {
    name: notificationsvmt0hH6diVMeta?.name ?? "settings-notifications",
    path: notificationsvmt0hH6diVMeta?.path ?? "notifications",
    meta: notificationsvmt0hH6diVMeta || {},
    alias: notificationsvmt0hH6diVMeta?.alias || [],
    redirect: notificationsvmt0hH6diVMeta?.redirect,
    component: () => import("/vercel/path0/pages/settings/notifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: testlK5QpQxp8eMeta?.name ?? "test",
    path: testlK5QpQxp8eMeta?.path ?? "/test",
    meta: testlK5QpQxp8eMeta || {},
    alias: testlK5QpQxp8eMeta?.alias || [],
    redirect: testlK5QpQxp8eMeta?.redirect,
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: usersrOJT0s7kzFMeta?.name ?? "users",
    path: usersrOJT0s7kzFMeta?.path ?? "/users",
    meta: usersrOJT0s7kzFMeta || {},
    alias: usersrOJT0s7kzFMeta?.alias || [],
    redirect: usersrOJT0s7kzFMeta?.redirect,
    component: () => import("/vercel/path0/pages/users.vue").then(m => m.default || m)
  },
  {
    name: workflowswfUdDI78SIMeta?.name ?? undefined,
    path: workflowswfUdDI78SIMeta?.path ?? "/workflows",
    meta: workflowswfUdDI78SIMeta || {},
    alias: workflowswfUdDI78SIMeta?.alias || [],
    redirect: workflowswfUdDI78SIMeta?.redirect,
    component: () => import("/vercel/path0/pages/workflows.vue").then(m => m.default || m),
    children: [
  {
    name: index2sIr7sMu1kMeta?.name ?? "workflows",
    path: index2sIr7sMu1kMeta?.path ?? "",
    meta: index2sIr7sMu1kMeta || {},
    alias: index2sIr7sMu1kMeta?.alias || [],
    redirect: index2sIr7sMu1kMeta?.redirect,
    component: () => import("/vercel/path0/pages/workflows/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  }
]