import type { RouteList } from '~auth/types';
import { RolesEnum } from '~auth/enums/roles.enum';

export const filterLinksByRole = (routes: RouteList[], role: string | null = null) => {
  const filteredRoutes: RouteList[] = [];
  routes.forEach(route => {
    // Determine if the route should be included according to the role
    let shouldInclude = false;
    if (role === RolesEnum.ADMIN) {
      shouldInclude = true; // Admin sees all routes
    } else {
      shouldInclude = !route.role || route.role === role; // Other roles see routes without role or with their specific role
    }

    // Create a copy of the route if you are going to include or have children
    if (shouldInclude || (route.children && route.children.length > 0)) {
      const newRoute = { ...route };

      // Apply recursity to children if they exist
      if (newRoute?.children && newRoute.children.length > 0) {
        newRoute.children = filterLinksByRole(newRoute?.children, role);
      }

      // Add the route to the result if it should be included or if any of its leaked children were included
      if (shouldInclude || (newRoute.children && newRoute.children.length > 0)) {
        filteredRoutes.push(newRoute);
      }
    }
  });

  return filteredRoutes;
}


export const formatLabel = (label: string) => {
  if (!label) {
    return ''
  }
  return label.charAt(0).toUpperCase() + label.slice(1)
}