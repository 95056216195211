import { useFetch } from '#app'

const readAsArrayBuffer = async (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsArrayBuffer(blob)
  })
}

const blobToBase64 = async (blob: Blob) => {
  let binary = ''
  const buffer = await readAsArrayBuffer(blob) as ArrayBuffer
  const bytes = new Uint8Array(buffer)
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

const multipartToJson = async (multipart: any) => {
  const entries = []
  for (const pair of multipart.entries()) {
    entries.push([pair[0], pair[1]])
  }

  const transformedEntries = await Promise.all(entries.map(async ([key, value]) => [
    key, value instanceof Blob || value instanceof File
      ? {
          value: await blobToBase64(value),
          type: value.type,
          name: value?.name || null
        }
      : value
  ]))

  return Object.fromEntries(transformedEntries)
}

const createRequestConfig = <T>(requestInfo: string, fetchOptions?: any, bodyInfo?: any) => {
  const { body, method, params, query, headers, ...moreOptions } = fetchOptions ?? { body: {}, method: 'GET', params: {}, query: {}, headers: {} }

  return {
    headers: {
      ...headers,
      ...bodyInfo?.headers
    },
    transform: (response: any) => response.result as T || response,
    initialCache: false,
    key: requestInfo + method || 'GET' + JSON.stringify(body || {}),
    method: 'POST',
    body: {
      payload: bodyInfo.body?.payload ? { ...bodyInfo.body?.payload } : {},
      requestOptions: {
        path: requestInfo,
        method: (method || 'GET').toUpperCase(),
        ...(params ? { params } : {}),
        ...(query ? { query } : {})
      }
    },
    ...moreOptions
  }
}

interface FormDataFields {
  name: string,
  fileOrValue: string | Blob,
  fileName?: string
}

export const useFormData = (files: Array<FormDataFields>) => {
  const form = new FormData()

  files.forEach(({ name, fileOrValue, fileName }: any) => {
    form.append(name, fileOrValue, fileName)
  })

  return form
}

export const extractBody = async (options: any) => {
  const fetchConfig = {
    body: {
      payload: {}
    },
    headers: {}
  }

  if (options?.body instanceof FormData) {
    fetchConfig.body.payload = await multipartToJson(options?.body)
    fetchConfig.headers = { 'Content-Type': 'multipart/form-data' }
  } else {
    fetchConfig.body.payload = options?.body ? { ...options?.body } : {}
  }
  return fetchConfig
}

export const useRemApi = async <Type>(requestInfo: string, fetchOptions?: any) => {
  const options = extractBody(fetchOptions)
  const requestConfig = createRequestConfig<Type>(requestInfo, fetchOptions, options)
  return await useFetch<Type>('/api/secure', requestConfig) as Type
}

export const useRawRemApi = async (requestInfo: string, fetchOptions?: any) => {
  const options = await extractBody(fetchOptions)
  const requestConfig = createRequestConfig(requestInfo, fetchOptions, options)
  return await $fetch('/api/secure', requestConfig)
}
