export default defineNuxtRouteMiddleware(async (to: any, from: any) => {
  const nuxtApp = useNuxtApp()
  const oryInstance: any = useOry()
  const {flow, id, error} = to?.query ?? null
  const { kratosFlowId, setFlowId, setKratosFlow } = useFlowOry()

  const whiteList = ['login', 'recovery', 'verification', 'failed']

  if (from.path.includes('home')) {
    setFlowId(null)
  }

  const flowIdRef = whiteList.some(path => to.path.includes(path)) ? flow ?? kratosFlowId.value : kratosFlowId.value

  try {
    if (!flowIdRef && !id) return

    if (id && nuxtApp?._route?.path?.includes('failed')) {
      setFlowId(null)
      nuxtApp.kratosError = (await oryInstance.getFlowError({id: id ?? error})).data
      return
    }

    const cookie = process.server ? useRequestHeaders(['cookie'])?.cookie : null

    if (nuxtApp?._route?.path?.includes('login') && flowIdRef) {
      const kratosFlow = (await oryInstance.getLoginFlow({id: flowIdRef, cookie})).data
      setKratosFlow(kratosFlow)
    } else if (nuxtApp?._route?.path?.includes('recovery') && flowIdRef) {
      const kratosFlow = (await oryInstance.getRecoveryFlow({id: flowIdRef, cookie})).data
      setKratosFlow(kratosFlow)
    } else if (nuxtApp?._route?.path?.includes('verification') && flowIdRef) {
      const kratosFlow = (await oryInstance.getVerificationFlow({id: flowIdRef, cookie})).data
      setKratosFlow(kratosFlow)
    } else if (nuxtApp?._route?.path?.includes('configuration') && flowIdRef) {
      const kratosFlow = (await oryInstance.getSettingsFlow({id: flowIdRef, cookie})).data
      setKratosFlow(kratosFlow)

      // refirect to login if the flow change password is sueccess
      const { userAuthOryData } = useUserAuthOry()
      const messageId = kratosFlow?.ui?.messages?.length > 0 ? kratosFlow.ui.messages[0].id : 0

      if (messageId === 1050001 && isLinkRecoveryMethod(userAuthOryData.value)) {
        const result = (await oryInstance.createBrowserLogoutFlow({cookie})).data
        return navigateTo(result?.logout_url, { external: true })
      }
      
    }

    return
  } catch (error: any) {
    useBugsnag().notify(error)
    setFlowId(null)
    throw createError({
      statusCode: error?.response?.status,
      statusMessage: 'Error al obtener el flujo de autenticación.',
      message: error?.response?.data?.error?.message ?? 'Error desconocido',
    })
  }
})