export default defineAppConfig({
  myLayer: {
    name: 'coreLayer'
  },
  ui: {
    global: true,
    primary: 'blue',
    gray: 'neutral',
    icons: ['heroicons', 'simple-icons'],
    button: {
      color: {
        primary: {
          solid: 'text-white bg-primary hover:bg-primary/70 dark:text-gray-600 dark:bg-secondary dark:hover:bg-secondary-600 dark:hover:border-secondary-400 dark:border-secondary',
          outline: 'ring-1 ring-inset ring-current text-primary dark:text-secondary-400 hover:bg-primary-50 disabled:bg-transparent dark:hover:bg-secondary-950 dark:disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-secondary-400',
        },
        basic: {
          solid: 'text-gray-600 bg-gray-200 hover:bg-gray-300 dark:text-gray-400 dark:bg-transparent dark:hover:bg-gray-800 dark:border-gray-500 border-2',
        },
        danger: {
          solid: 'text-red-600 bg-red-100 hover:bg-red-200 dark:text-white dark:bg-red-500 dark:hover:bg-red-600 dark:border-red-500',
          outline: 'ring-1 ring-inset ring-current text-red-500 dark:text-red-400 hover:bg-red-50 disabled:bg-transparent dark:hover:bg-red-950 dark:disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-red-500 dark:focus-visible:ring-red-400 inline-flex items-center'
        },
      },
      default: {
        size: 'md',
        color: 'primary',
        variant: 'solid',
        loadingIcon: 'i-heroicons-arrow-path-16-solid',
      }
    },
    input: {
      variant: {
        outline:'shadow-sm bg-transparent text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-400 focus:ring-2 focus:ring-{color}-500 dark:focus:ring-secondary-400',
      },
      default: {
        size: 'md',
        color: 'primary',
        variant: 'outline',
        loadingIcon: 'i-heroicons-arrow-path-20-solid',
      }
    },
    notification: {
      progress: {
        background: 'bg-primary dark:bg-secondary',
      }
    },
    skeleton: {
      background: 'bg-gray-200 dark:bg-gray-700',
    },
    alert: {
      default: {
        color: 'green',
        variant: 'subtle',
      },
    },
    tooltip: {
      default: {
        openDelay: 500
      }
    },
    horizontalNavigation: {
      active: 'text-gray-900 dark:text-white after:bg-primary-500 dark:after:bg-secondary-400 after:rounded-full',
    }
  },
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: string
    }
  }
}
