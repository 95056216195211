import { RolesEnum } from '~auth/enums/roles.enum';
import type { RouteList } from '../types';


export const useSidebarLinks = () => {
  const { userAuthOryData } = useUserAuthOry()
  const isEmailVerified = ref(userAuthOryData.value?.identity?.verifiable_addresses[0]?.status)
    
  const customLinks: RouteList[] = [{
    id: 'home',
    label: 'Inicio',
    icon: 'i-heroicons-home',
    to: '/home',
    tooltip: {
      text: 'Home',
      shortcuts: ['G', 'H']
    },
  }, {
    id: 'test',
    label: 'Test',
    icon: 'i-heroicons-inbox',
    to: '/test',
    tooltip: {
      text: 'Test',
      shortcuts: ['T']
    }
  }, {
    id: 'workflows',
    label: 'Workflows',
    icon: 'i-heroicons-wrench-screwdriver',
    to: '/workflows',
    tooltip: {
      text: 'Workflows',
      shortcuts: ['G', 'F']
    },
    children: [{
      label: 'Flujos',
      to: '/workflows',
      exact: true
    }, {
      label: 'Crear ',
      to: '/workflows/new'
    }, {
      label: 'Reglas',
      to: '/workflows/rules'
    }, {
      label: 'Plantillas',
      to: '/workflows/templates'
    }, {
      label: 'Autocompletar',
      to: '/workflows/autofill',
      badge: 'Signt'
    }]
  }, {
    id: 'people',
    label: 'People',
    icon: 'i-heroicons-user-group',
    to: '/people',
    tooltip: {
      text: 'Personas',
      shortcuts: ['G', 'U']
    },
    children: [{
      label: 'Personas',
      to: '/people'
    }, {
      label: 'Grupos',
      to: '/people/groups'
    }, {
      label: 'Configuración',
      to: '/people/settings'
    }]
  }, {
    id: 'signt',
    label: 'Signt',
    icon: 'i-heroicons-document-check',
    to: '/signt',
    tooltip: {
      text: 'Signt',
      shortcuts: ['G', 'U']
    },
    children: [{
      label: 'Documentos',
      to: '/signt/documents'
    }, {
      label: 'Plantillas',
      to: '/signt/templates'
    }, {
      label: 'Firmantes',
      to: '/signt/signers'
    }, {
      label: 'Externos',
      to: '/signt/externals'
    }]
  }, {
    id: 'configuration',
    label: 'Configuración',
    to: '/configuration',
    icon: 'i-heroicons-cog-8-tooth',
    children: [{
      label: 'Perfil',
      badge: isEmailVerified.value === 'sent' ? {
        color: 'red',
        label: 'i-heroicons-exclamation-circle-solid',
      } : undefined,
      to: '/configuration',
      exact: true,
    }, {
      label: 'Usuarios',
      to: '/configuration/users',
      role: RolesEnum.ADMIN
    }],
    tooltip: {
      text: 'configuration',
      shortcuts: ['G', 'C']
    }
  }]

  const linksFilteredByRole = filterLinksByRole(customLinks, userAuthOryData.value?.role)

  const isLinkAllowedByRole = (toPath: string, role: string) => {
    let isNavigationAllowed = false
    function search(links: any, toPath: string) {
      for (const link of links) {
        // Verify if the current path is included in some HREF of the routes
        if (toPath.includes(link.to)) {
          // If the route coincides but does not have the role defined, or has the required role, allows navigation
          if (!link?.role || (link?.role && link?.role === role)) {
            isNavigationAllowed = true
            return true // Find coincidence and meet the required role
          } else {
            return false // Find coincidence but does not meet the required role
          }
        }
        if (link?.children && search(link.children, toPath)) {
          return true
        }
      }
      return false // No route was found that coincides
    }
  
    search(customLinks, toPath)
    return isNavigationAllowed
  }

  return {
    linksFilteredByRole,
    isLinkAllowedByRole
  }
}